
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseIcon from '../base/BaseIcon.vue'

@Component({
  name: 'HeaderMenuIconButton',
  components: { BaseIcon },
})
export default class HeaderMenuIconButton extends Vue {
  @Prop({ required: true }) icon! : string

  @Prop({ required: true }) label! : string

  @Emit()
  private click () : boolean {
    return true
  }
}
